import React from 'react'
import styled from 'styled-components'

const NavBG = styled.nav`
    display: flex;
    height: auto;
    width: 100%;
    background-color:
        ${props =>
            props.bg  ?  props.bg  :
            `var(--color-black)`
        };
    opacity: .7;
    position: fixed;
    transition: var(--trns-easeio-slow);
    z-index: var(--z-cover);

    &.solid {
        opacity: 1;
    }

    &.hidden {
        transform: translateY(-100%);
    }
`

export default function Navbar(props) {
     return (
          <NavBG
               {...props}
               className={(
                    props.className    
                    ?  `${props.className} `  
                    :  ''
               )}
          >
      
          </NavBG>
     )
}
