import styled from 'styled-components'

const Topbar = styled.div`
     background: #353535;
     height: auto;
     min-height: 15px;
     width: 100%;
     position: fixed;
     z-index: 1;
`

export default (Topbar)