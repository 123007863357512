import React from 'react'
import styled from 'styled-components'

const LinkList = styled.ul`
    justify-content: flex-end;
    align-self: center;
    flex: 0 0 auto;
    margin-left: auto;
    opacity: 1;
    display: flex;

    &:not(.hide) {
        position: relative;
    }
    
    &.hide {
        height: 0;
        opacity: 0;
        position: absolute;
        transition: var(--trns-linear-std);
        overflow: hidden;

        li {
            transform: translateY(-15px);
            opacity: 0;
        }

        &.open {
            background-color:
                ${props =>
                    props.bg  ?  props.bg  :
                    `var(--color-black)`
                };
            text-align: center;
            display: block;
            width: 100%;
            height: 100vh;
            color: #fff;
            top: 0;
            left: 0;
            opacity: 1;
            z-index: var(--z-boost);
            transition: var(--trns-linear-fast);

            li {
                transform: translateY(50%);
                opacity: 1;
            }
        }
    }
`

const LinksContainer = React.forwardRef((props, linksListRef) =>  (
    <LinkList 
        {...props}
        ref={linksListRef} 
        className={(
            props.className    
            ?  `${props.className} `  
            :  ''
        )}
    >
        { props.children }
    </LinkList>
))

export default LinksContainer