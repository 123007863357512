import React, { Component } from 'react'
import styled from 'styled-components'

const Toggle = styled.a`
     --default-padding: 20px;

     display: inline-block;
     margin: 5px;

     height: 0;
     padding: 
          ${props => 
               props.size  ?  `${props.size * .3}px ${props.size}px ${props.size * .48}px 0px`  :
               `calc(var(--default-padding) * .3) var(--default-padding) calc(var(--default-padding) * .48) 0px`
          };
     position: relative;
     cursor: pointer;
     z-index: var(--z-apex);

     span, 
     span:before,
     span:after {
          height: 0px;
          width: 0px;
          background-color: 
               ${props =>
                    props.color  ?  props.color  :
                    `var(--color-white);`
               };
          padding-bottom:
               ${props =>
                    props.size     ?   `${props.size * .17}px`             :
                    `17%`
               };
          padding-left: 
               ${props => 
                    props.toggle  ===  `hamtoxsplit`  ?   `50%`            :
                    `100%`
               };
          border-radius: 1px;
          display: block;
          position: absolute;
          content:  '';
          transition: 
               ${props => 
                    props.speed    ?   `all ${props.speed}ms ease-in-out`         :
                    `var(--trns-easeio-std)`
               };
          transition-duration:
               ${props => 
                    props.toggle  ===  `hamtoxsqueeze`   &&
                    props.speed                          ?  `${props.speed}ms`                  :
                    props.toggle  ===  `hamtoxsqueeze`   ?  `calc(var(--speed-std) / 2)`        :
                    props.toggle  ===  `hamtoxflip`      &&
                    props.speed                          ?  `${props.speed}ms`                  :
                    props.toggle  ===  `hamtoxflip`      ?  `var(--speed-slow)`                 :
                    ``
               };
          transition-delay:
               ${props => 
                    props.toggle  ===  `hamtoxsqueeze`   &&
                    props.speed                          ?  `${props.speed / 2}ms`              :
                    props.toggle  ===  `hamtoxsqueeze`   ?  `calc(var(--speed-std) / 2)`        :
                    ``
               };
          animation-duration:
               ${props =>
                    props.speed    ?   `${props.speed}ms`     :
                    `var(--speed-slow)`
               };
          animation-fill-mode: forwards;
     }

     span {
          &:nth-child(odd) {
               border-radius:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     ?  `1px 0px 0px 1px`           :
                         ``
                    };
          }
          &:nth-child(even) {
               border-radius:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     ?  `0px 1px 1px 0px`           :
                         ``
                    };
          }
          &:nth-child(1) {
               transform:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     ?  `translateX(0%)`            :
                         ``
                    };
          }
          &:nth-child(2) {
               background-color:
                    ${props => 
                         props.toggle  ===  `hamtoxflip`      ?  `transparent`    :
                         ``
                    };
               display:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     ?  `block`          :
                         props.toggle  ===  `hamtoxflip`      ?  `block`          :
                         `none`
                    };
               transform:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     ?  `translateX(100%)`         :
                         ``
                    };
          }
          &:nth-child(3) {
               display:
                    ${props => 
                         props.toggle  !==  `hamtoxsplit`     ?  `none`           :
                         ``
                    };
               transform:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     &&
                         props.size                           ?   `translateX(0%) translateY(-${props.size * .3}px)`    :
                         props.toggle  ===  `hamtoxsplit`     ?   `translateX(0%) translateY(calc(20px * -.3))`          :
                         ``
                    };
          }
          &:nth-child(4) {
               display:
                    ${props => 
                         props.toggle  !==  `hamtoxsplit`     ?  `none`           :
                         ``
                    };
               transform:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     &&
                         props.size                           ?   `translateX(100%) translateY(-${props.size * .3}px)`  :
                         props.toggle  ===  `hamtoxsplit`     ?   `translateX(100%) translateY(calc(20px * -.3))`       :
                         ``
                    };
          }
          &:nth-child(5) {
               display:
                    ${props => 
                         props.toggle  !==  `hamtoxsplit`     ?  `none`           :
                         ``
                    };
               transform:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     &&
                         props.size                           ?   `translateX(0%) translateY(${props.size * .3}px)`     :
                         props.toggle  ===  `hamtoxsplit`     ?   `translateX(0%) translateY(calc(20px * .3))`          :
                         ``
                    };
          }
          &:nth-child(6) {
               display:
                    ${props => 
                         props.toggle  !==  `hamtoxsplit`     ?  `none`           :
                         ``
                    };
               transform:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     &&
                         props.size                           ?   `translateX(100%) translateY(${props.size * .3}px)`    :
                         props.toggle  ===  `hamtoxsplit`     ?   `translateX(100%) translateY(calc(20px * .3))`        :
                         ``
                    };
          }

          &:before {
               display:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     ?  `none`           :
                         props.toggle  ===  `hamtoxflip`      ?  `none`           :
                         ``
                    };
               transform:
                    ${props =>
                         props.size     ?   `translateX(-${props.size}px) translateY(-${props.size * .3}px)`  :
                         `translateX(-20px) translateY(calc(20px * -.3))`
                    };
               transform-origin:
                    ${props => 
                         props.toggle  ===  `hamtox`          ?  `top left`       :
                         props.toggle  ===  `hamtoxroll`      ?  `top left`       :
                         props.toggle  ===  `hamtoxspin`      ?  `top left`       :
                         props.toggle  ===  `hamtoxsqueeze`   ?  `center center`  :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `left`            ?  `top right`      :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `right`           ?  `top left`       :
                         props.toggle  ===  `hamtoarrow`      ?  `top right`      :
                         ``
                    };
               animation-name:
                    ${props =>
                         props.toggle  ===  `hamtoxsqueeze`   ?  `squeeze-before-out`    :
                         ``
                    };
               animation-direction: reverse;
               animation-fill-mode: backwards;
          }

          &:nth-child(2) {
               &:before {
                    display:
                         ${props => 
                              props.toggle  ===  `hamtoxflip`  ?  `block`         :
                              ``
                         };
               }
          }
          
          &:after {
               display:
                    ${props => 
                         props.toggle  ===  `hamtoxsplit`     ||
                         props.toggle  ===  `hamtoxflip`      ?  `none`           :
                         ``
                    };
               transform:
                    ${props =>
                         props.size     ?   `translateX(-${props.size}px) translateY(${props.size * .3}px)`  :
                         `translateX(-20px) translateY(calc(20px * .3))`
                    };
               transform-origin:
                    ${props => 
                         props.toggle  ===  `hamtox`          ?  `bottom left`    :
                         props.toggle  ===  `hamtoxroll`      ?  `bottom left`    :
                         props.toggle  ===  `hamtoxspin`      ?  `bottom left`    :
                         props.toggle  ===  `hamtoxsqueeze`   ?  `center center`  :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `left`            ?  `bottom right`   :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `right`           ?  `bottom left`    :
                         props.toggle  ===  `hamtoarrow`      ?  `bottom right`   :
                         ``
                    };
               animation-name:
                    ${props =>
                         props.toggle  ===  `hamtoxsqueeze`   ?  `squeeze-after-out`     :
                         ``
                    };
               animation-direction: reverse;
               animation-fill-mode: backwards;
          }

          &:nth-child(2) {
               &:after {
                    display:
                         ${props => 
                              props.toggle  ===  `hamtoxflip`  ?  `block`         :
                              ``
                         };
               }
          }
     }

     &.active {
          span {
               &:nth-child(1) {
                    background-color:
                         ${props => 
                              props.toggle  ===  `hamtoxsplit`  ||
                              props.toggle  ===  `hamtoxflip`   ?  `transparent`        :
                              ``
                         };
                    transform:
                         ${props => 
                              props.toggle  ===  `hamtoxsplit`  ?  `translateX(-100%)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `up`           &&
                              props.spin    ===  `cw`           ?  `translateX(0%)     translateY(-500%) rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `up`           &&
                              props.spin    ===  `ccw`          ?  `translateX(0%)     translateY(-500%) rotate(-360deg)` :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `up`           ?  `translateX(0%)     translateY(-500%) rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `down`         &&
                              props.spin    ===  `cw`           ?  `translateX(0%)     translateY(500%)  rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `down`         &&
                              props.spin    ===  `ccw`          ?  `translateX(0%)     translateY(500%)  rotate(-360deg)` :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `down`         ?  `translateX(0%)     translateY(500%)  rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `left`         &&
                              props.spin    ===  `cw`           ?  `translateX(-100%)  translateY(0%)    rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `left`         &&
                              props.spin    ===  `ccw`          ?  `translateX(-100%)  translateY(0%)    rotate(-360deg)` :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `left`         ?  `translateX(-100%)  translateY(0%)    rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `right`        &&
                              props.spin    ===  `cw`           ?  `translateX(100%)   translateY(0%)    rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `right`        &&
                              props.spin    ===  `ccw`          ?  `translateX(100%)   translateY(0%)    rotate(-360deg)` :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.dir     ===  `right`        ?  `translateX(100%)   translateY(0%)    rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.spin    ===  `cw`           ?  `translateX(100%)   translateY(0%)    rotate(360deg)`  :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.spin    ===  `ccw`          ?  `translateX(100%)   translateY(0%)    rotate(-360deg)` :
                              props.toggle  ===  `hamtoxflip`   ?  `translateX(100%)   translateY(0%)    rotate(360deg)`  :
                              ``
                         };
               }
               &:nth-child(2) {
                    background-color:
                         ${props => 
                              props.toggle  ===  `hamtoxsplit`  ?  `transparent`                       :
                              ``
                         };
                    transform:
                         ${props => 
                              props.toggle  ===  `hamtoxsplit`  ?  `translateX(200%) `                                     :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.spin    ===  `cw`           ?  `translateX(0%)  translateY(0%)  rotate(225deg)`        :
                              props.toggle  ===  `hamtoxflip`   &&
                              props.spin    ===  `ccw`          ?  `translateX(0%)  translateY(0%)  rotate(-225deg)`       :
                              props.toggle  ===  `hamtoxflip`   ?  `translateX(0%)  translateY(0%)  rotate(225deg)`        :
                              ``
                         };
               }
               &:nth-child(3) {
                    transform:
                         ${props => 
                              props.toggle  ===  `hamtoxsplit`  ?  `translateX(25%) translateY(-75%) rotate(45deg)`        :
                              ``
                         };
               }
               &:nth-child(4) {
                    transform:
                         ${props => 
                              props.toggle  ===  `hamtoxsplit`  ?  `translateX(75%) translateY(-75%) rotate(-45deg)`  :
                              ``
                         };
               }
               &:nth-child(5) {
                    transform:
                         ${props => 
                              props.toggle  ===  `hamtoxsplit`  ?  `translateX(25%) translateY(75%) rotate(-45deg)`   :
                              ``
                         };
               }
               &:nth-child(6) {
                    transform:
                         ${props => 
                              props.toggle  ===  `hamtoxsplit`  ?  `translateX(75%) translateY(75%) rotate(45deg)`    :
                              ``
                         };
               }
               background-color:
                    ${props =>
                         props.toggle  ===  `hamtox`          ||
                         props.toggle  ===  `hamtoxsqueeze`   ||
                         props.toggle  ===  `hamtoxroll`      ||
                         props.toggle  ===  `hamtoxspin`      ?  `transparent`  :
                         ``
                    };
               margin:
                    ${props =>
                         props.toggle  ===  `hamtoxroll`      ?  `0px 0px 0px 10%`  : 
                         props.toggle  ===  `hamtoxspin`      ?  `0px 0px 0px 10%`  : 
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `left`            ?  `0px 0px 0px 10%`  : 
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `right`           ?  `0px 10% 0px 0px`  : 
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `up`              ?  `5% 0px 0px 15%`   : 
                         props.dir     ===  `down`            ?  `0px 0px 0px 15%`  : 
                         props.toggle  ===  `hamtoarrow`      ?  `0px 0px 0px 10%`  : 
                         ``
                    };
               padding-left:
                    ${props =>
                         props.toggle  ===  `hamtoarrow`      &&
                         (props.dir     ===  `up`             ||
                         props.dir     ===  `down`)           ?  `35%`    :
                         props.toggle  ===  `hamtoarrow`      ?  `45%`    :
                         ``
                    };
               padding-right:
                    ${props =>
                         props.toggle  ===  `hamtoarrow`      &&
                         (props.dir     ===  `up`             ||
                         props.dir     ===  `down`)           ?  `35%`    :
                         props.toggle  ===  `hamtoarrow`      ?  `45%`    :
                         ``
                    };
               transform: 
                    ${props =>
                         props.toggle  ===  `hamtoxroll`      &&
                         props.spin    ===  `cw`              ?  `rotate(90deg)`      :
                         props.toggle  ===  `hamtoxroll`      &&
                         props.spin    ===  `ccw`             ?  `rotate(-90deg)`     :
                         props.toggle  ===  `hamtoxroll`      ?  `rotate(90deg)`      :
                         props.toggle  ===  `hamtoxspin`      &&
                         props.spin    ===  `cw`              ?  `rotate(270deg)`     :
                         props.toggle  ===  `hamtoxspin`      &&
                         props.spin    ===  `ccw`             ?  `rotate(-270deg)`    :
                         props.toggle  ===  `hamtoxspin`      ?  `rotate(270deg)`     :
                         props.toggle  ===  `hamtoarrow`      &&
                         (props.dir    ===  `left`            ||
                         props.dir     ===  `right`)          &&
                         props.spin    ===  `cw`              ?  `rotate(180deg)`     :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `up`              &&
                         props.spin    ===  `cw`              ?  `rotate(270deg)`     :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `down`            &&
                         props.spin    ===  `cw`              ?  `rotate(270deg)`     :
                         props.toggle  ===  `hamtoarrow`      &&
                         (props.dir    ===  `left`            ||
                         props.dir     ===  `right`)          &&
                         props.spin    ===  `ccw`             ?  `rotate(-180deg)`    :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `up`              &&
                         props.spin    ===  `ccw`             ?  `rotate(-270deg)`    :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `down`            &&
                         props.spin    ===  `ccw`             ?  `rotate(-270deg)`    :
                         props.toggle  ===  `hamtoarrow`      &&
                         (props.dir    ===  `left`            ||
                         props.dir     ===  `right`)          ?  `rotate(180deg)`     :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `up`              ?  `rotate(270deg)`     :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.dir     ===  `down`            ?  `rotate(270deg)`     :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.spin    ===  `cw`              ?  `rotate(180deg)`     :
                         props.toggle  ===  `hamtoarrow`      &&
                         props.spin    ===  `ccw`             ?  `rotate(-180deg)`    :                         
                         props.toggle  ===  `hamtoarrow`      ?  `rotate(180deg)`     :
                         ``
                    };
               transition-duration:
                    ${props => 
                         props.toggle  ===  `hamtoxsqueeze`   ?  `0ms`       :
                         ``
                    };

               &:before {
                    padding-left: 
                         ${props =>
                              props.toggle  ===  `hamtox`          ||
                              props.toggle  ===  `hamtoxroll`      ||
                              props.toggle  ===  `hamtoxspin`      ||
                              props.toggle  ===  `hamtoxflip`      ?  `90%`  :
                              props.toggle  ===  `hamtoarrow`      &&
                              (props.dir    ===  `up`              ||
                              props.dir     ===  `down`)           ?  `75%`  :
                              props.toggle  ===  `hamtoarrow`      ?  `50%`  :
                              ``
                         };
                    transform:
                         ${props =>
                              props.toggle  ===  `hamtox`          ?  `translateX(-83%)  translateY(-170%)  rotate(45deg)`    :
                              props.toggle  ===  `hamtoxroll`      &&
                              props.spin    ===  `cw`              ?  `translateX(-83%)  translateY(-110%)  rotate(45deg)`    :
                              props.toggle  ===  `hamtoxroll`      &&
                              props.spin    ===  `ccw`             ?  `translateX(-83%)  translateY(-235%)  rotate(45deg)`    :
                              props.toggle  ===  `hamtoxroll`      ?  `translateX(-83%)  translateY(-110%)  rotate(45deg)`    :
                              props.toggle  ===  `hamtoxspin`      &&
                              props.spin    ===  `cw`              ?  `translateX(-86%)  translateY(-230%)  rotate(45deg)`    :
                              props.toggle  ===  `hamtoxspin`      &&
                              props.spin    ===  `ccw`             ?  `translateX(-83%)  translateY(-115%)  rotate(45deg)`    :
                              props.toggle  ===  `hamtoxspin`      ?  `translateX(-86%)  translateY(-230%)  rotate(45deg)`    :
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.size                           &&
                              props.spin    ===  `cw`              ?  `translateX(-20%)  translateY(45%)    rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            &&
                              props.size                           &&
                              props.spin    ===  `cw`              ?  `translateX(-85%)  translateY(205%)   rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.spin    ===  `cw`              ?  `translateX(-20%)  translateY(75%)    rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            &&
                              props.spin    ===  `cw`              ?  `translateX(-95%)  translateY(325%)   rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.size                           &&
                              props.spin    ===  `ccw`             ?  `translateX(-85%)  translateY(205%)   rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            &&
                              props.size                           &&
                              props.spin    ===  `ccw`             ?  `translateX(-20%)  translateY(45%)    rotate(45deg)`    :
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.spin    ===  `ccw`             ?  `translateX(-95%)  translateY(325%)   rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            &&
                              props.spin    ===  `ccw`             ?  `translateX(-20%)  translateY(75%)    rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.size                           ?  `translateX(-20%)  translateY(45%)    rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            &&
                              props.size                           ?  `translateX(-85%)  translateY(205%)   rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `left`            ?  `translateX(20%)   translateY(50%)    rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `right`           ?  `translateX(-95%)  translateY(-20%)   rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              ?  `translateX(-20%)  translateY(75%)    rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            ?  `translateX(-95%)  translateY(325%)   rotate(45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      ?  `translateX(20%)   translateY(50%)    rotate(45deg)`    :
                              ``
                         };
                    animation-name:
                         ${props =>
                              props.toggle  ===  `hamtoxsqueeze`   ?  `squeeze-before-in`    :
                              ``
                         };
               }

               &:nth-child(2) {
                    &:before {
                         transform:
                              ${props => 
                                   props.toggle  ===  `hamtoxflip`   ?  `translateX(-105%)  translateY(0%)  rotate(90deg)`   :
                                   ``
                              };
                    }
               }
               
               &:after {
                    padding-left: 
                         ${props =>
                              props.toggle  ===  `hamtox`          ||
                              props.toggle  ===  `hamtoxroll`      ||
                              props.toggle  ===  `hamtoxspin`      ||
                              props.toggle  ===  `hamtoxflip`      ?  `90%`  :
                              props.toggle  ===  `hamtoarrow`      &&
                              (props.dir    ===  `up`              ||
                              props.dir     ===  `down`)           ?  `75%`  :
                              props.toggle  ===  `hamtoarrow`      ?  `50%`  :
                              ``
                         };
                    transform:
                         ${props =>
                              props.toggle  ===  `hamtox`          ?  `translateX(-83%)  translateY(175%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoxroll`      &&
                              props.spin    ===  `cw`              ?  `translateX(-83%)  translateY(230%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoxroll`      &&
                              props.spin    ===  `ccw`             ?  `translateX(-83%)  translateY(115%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoxroll`      ?  `translateX(-83%)  translateY(230%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoxspin`      &&
                              props.spin    ===  `cw`              ?  `translateX(-86%)  translateY(110%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoxspin`      &&
                              props.spin    ===  `ccw`             ?  `translateX(-83%)  translateY(235%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoxspin`      ?  `translateX(-86%)  translateY(110%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.size                           &&
                              props.spin    ===  `cw`              ?  `translateX(-20%)  translateY(-45%)   rotate(-45deg)`   : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            &&
                              props.size                           &&
                              props.spin    ===  `cw`              ?  `translateX(-85%)  translateY(-205%) rotate(-45deg)`    :
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.spin    ===  `cw`              ?  `translateX(-20%)  translateY(-25%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoarrow`      && 
                              props.dir     ===  `down`            &&
                              props.spin    ===  `cw`              ?  `translateX(-95%)  translateY(-275%) rotate(-45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.size                           &&
                              props.spin    ===  `ccw`             ?  `translateX(-85%)  translateY(-205%) rotate(-45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            &&
                              props.size                           &&
                              props.spin    ===  `ccw`             ?  `translateX(-20%)  translateY(-45%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.spin    ===  `ccw`             ?  `translateX(-95%)  translateY(-275%)  rotate(-45deg)`   :
                              props.toggle  ===  `hamtoarrow`      && 
                              props.dir     ===  `down`            &&
                              props.spin    ===  `ccw`             ?  `translateX(-20%)  translateY(-25%) rotate(-45deg)`     : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              &&
                              props.size                           ?  `translateX(-20%)  translateY(-45%)   rotate(-45deg)`   : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `down`            &&
                              props.size                           ?  `translateX(-85%)  translateY(-205%) rotate(-45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `left`            ?  `translateX(20%)   translateY(-50%)  rotate(-45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `right`           ?  `translateX(-95%)  translateY(20%)   rotate(-45deg)`    :
                              props.toggle  ===  `hamtoarrow`      &&
                              props.dir     ===  `up`              ?  `translateX(-20%)  translateY(-25%)  rotate(-45deg)`    :
                              props.toggle  ===  `hamtoarrow`      && 
                              props.dir     ===  `down`            ?  `translateX(-95%)  translateY(-275%) rotate(-45deg)`    : 
                              props.toggle  ===  `hamtoarrow`      ?  `translateX(20%)   translateY(-50%)  rotate(-45deg)`    : 
                              ``
                         };
                    animation-name:
                         ${props =>
                              props.toggle  ===  `hamtoxsqueeze`   ?  `squeeze-after-in`    :
                              ``
                         };
               }

               &:nth-child(2) {
                    &:after {

                         transform:
                              ${props => 
                                   props.toggle  ===  `hamtoxflip`   ?  `translateX(-105%)  translateY(0%)  rotate(0deg)`   :
                                   ``
                              };
                    }
               }
          }
     }

     &:not(.blocked) {
          span {
               &:before,
               &:after {
                    animation-direction: normal;
                    animation-fill-mode: forwards;
               }
          }
     }

     @keyframes squeeze-before-in {
          0% {
               padding-left: 100%;
          }
          50% {
               transform: translateX(-100%) translateY(0%) rotate(0deg);
               padding-left: 100%;
          }
          100% {
               transform: translateX(-105%) translateY(0%) rotate(45deg);
               padding-left: 90%;
          }
     }

     @keyframes squeeze-before-out {
          0% {
               transform: translateX(-105%) translateY(0%) rotate(-45deg);
               padding-left: 90%;
          }
          50% {
               transform: translateX(-100%) translateY(0%) rotate(0deg);
               padding-left:100%;
          }
          100% {
               transform: translateX(-100%) translateY(-176%) rotate(0deg);
               padding-left: 100%;
          }
     }

     @keyframes squeeze-after-in {
          0% {
               padding-left: 100%;
          }
          50% {
               transform: translateX(-100%) translateY(0%) rotate(0deg);
               padding-left: 100%;
          }
          100% {
               transform: translateX(-105%) translateY(0%) rotate(-45deg);
               padding-left: 90%;
          }
     }

     @keyframes squeeze-after-out {
          0% {
               transform: translateX(-105%) translateY(0%) rotate(45deg);
               padding-left: 90%;
          }
          50% {
               transform: translateX(-100%) translateY(0%) rotate(0deg);
               padding-left: 100%;
          }
          100% {
               transform: translateX(-100%) translateY(176%) rotate(0deg);
               padding-left: 100%;
          }
     }
`

export default class NavToggle extends Component {
     constructor(props) {
          super(props)
          this.state = {
               switched: false,
               blocked: true,
          }

          this.toggleSwitch = this.toggleSwitch.bind(this)
     }

     toggleSwitch() {    
          if (this.state.switched) {
               this.setState({
                    switched: false
               })
          }
          else {
               this.setState({
                    switched: true
               })
          }

          this.setState({
               blocked: false
          })
     }

     componentDidMount(){
          if (this.props.auto) {
               // setInterval(this.toggleSwitch, 1000)
          }
     }

     render() {
          return (
               <Toggle 
                    onClick={this.toggleSwitch}
                    {...this.props} 
                    className={
                         (this.state.switched     ?  'active '                   :  '') +
                         (this.state.blocked      ?  'blocked '                  :  '') +
                         (this.props.className    ?  `${this.props.className} `  :  '')
                    }
               >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
               </Toggle>
          )
     }
}