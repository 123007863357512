import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import Topbar from './Topbar'
import Nav from '../components/Nav/Nav'

const Header = ({ siteTitle }) => (
  <div>
    <Topbar />
    <Nav>
      <Link to='/'>Home</Link>
      <Link to='about'>About</Link>
      <Link to='page-2'>Page 2</Link>
      <Link to='404'>404</Link>
    </Nav>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
