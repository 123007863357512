import React from 'react'
import styled from 'styled-components'

const Element = styled.div`
    display: flex;
    flex: 0 0 auto;

    img {
      height: 100%;
      margin: 0;
    }
`

const Brand = React.forwardRef((props, brandRef) => (
  <Element 
    {...props}
    ref={brandRef}
  > 
    { props.children }
  </Element>
))

export default Brand