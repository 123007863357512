import React from 'react'
import styled from 'styled-components'

const Link = styled.li`
    list-style-type: none;
    padding: 5px 10px;
    transition: var(--trns-linear-std);
    
    a {
        color: var(--color-white);
    }
`

const Links = React.forwardRef((props, linkRef) =>  (
    <Link 
        {...props}
        ref={linkRef} 
        className={(
            props.className    
            ?  `${props.className} `  
            :  ''
        )}
    >
        { props.children }
    </Link>
))

export default Links