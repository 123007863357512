import React, { Component } from 'react'
import styled from 'styled-components'

// ++ Import Container when created
// ++ Separate Functions for cleaner component

import Navbar from './Navbar'
import Brand from './Brand'
import LinksContainer from './LinksContainer'
import Links from './Links'
import NavToggle from './NavToggle'

import Logo from '../../images/logo.svg'

const Toggle = styled(NavToggle)`
    flex-direction: column;
    align-self: center;
    justify-content: flex-end;
    flex: 0 0 auto;
    margin-left: auto;

    // ++ These need moved to main NavToggle
        // !! Currently breaks other non-navigation toggles on page

    &:not(.hide) {
        display: flex;
    }

    &.hide {
        display: none;
    }
`

export default class Nav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navbarSolid:                false,
            navbarHidden:               false,
            linksWidth:                 0,
            mobileNav:                  false,
            navToggleActive:            false,
            navToggleAnimationBlocked:  true,
        }

        this.brandRef           =   React.createRef()
        this.linksListRef       =   React.createRef()
        this.toggle             =   React.createRef()
        this.linkRef            =   React.createRef()

        this.onScroll           =   this.onScroll.bind(this)
        this.stick              =   this.stick.bind(this)
        this.unstick            =   this.unstick.bind(this)
        this.solidBar           =   this.solidBar.bind(this)
        this.ghostBar           =   this.ghostBar.bind(this)
        this.eachLinkWidth      =   this.eachLinkWidth.bind(this)
        this.onResize           =   this.onResize.bind(this)
        this.throttle           =   this.throttle.bind(this)
        this.staggerAnimation   =   this.staggerAnimation.bind(this)
        this.toggleActive       =   this.toggleActive.bind(this)
    }

    onScroll = () => {
        var distance = 400
        
        window.scrollY > this.prev    &&
        window.scrollY > distance     ?    this.unstick()   :
        window.scrollY < this.prev    &&  
        window.scrollY > distance     ?    this.stick()     :
        window.scrollY > distance     ?    this.unstick()   :   this.stick()
  
        window.scrollY > 10           ?    this.solidBar()  :   this.ghostBar()

        this.prev = window.scrollY
   }

    stick() {
        this.setState({
            navbarHidden: false
        })
    }
    
    unstick() {
        this.setState({
            navbarHidden: true
        })
    }
    
    solidBar() {
        this.setState({
            navbarSolid: true
        }) 
    }
    
    ghostBar() {
        this.setState({
            navbarSolid: false
        })
    }

    throttle = () => {
        var delay = 250;
        var throttled = false;
        var timeout = false;

        if (!throttled) {
            this.onResize();
            throttled = true;

            setTimeout(function() {
                throttled = false;
            }, delay); 
        } 

        clearTimeout(timeout);
        timeout = setTimeout(this.onResize, delay);  
    }

    eachLinkWidth() {
        let linksWidth = 0

        for (var i = 0; i < this.linksListRef.current.children.length; i++) {  
            linksWidth += this.linksListRef.current.children[i].offsetWidth
        }

        this.setState({
            linksWidth: linksWidth
        })
    }

    createEachChild() {
        return this.props.children.map((item, index) => (
            <Links ref={this.linkRef} key={index}>
                {item}
            </Links>
        ));
    }

    onResize() {    
        var brandWidth = this.brandRef.current.offsetWidth
        var windowWidth = window.innerWidth;
            
        if ((brandWidth + this.state.linksWidth + 50) > windowWidth) {
            this.setState({
                mobileNav: true
            })
        }
        else {
            this.setState({
                mobileNav: false
            })
        } 
    }

    staggerAnimation(elem) {
        let delay = 0

        for (var i = 0; i < elem.children.length; i++) {
            delay = (i * 35)
            elem.children[i].style.transitionDelay = delay + 'ms'
        }
    }

    toggleActive() {    
        if (this.state.navToggleActive) {
             this.setState({
                  navToggleActive: false
             })
        }
        else {
             this.setState({
                  navToggleActive: true
             })
        }

        this.setState({
            navToggleAnimationBlocked: false
        })
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll)
        this.eachLinkWidth()
        window.addEventListener('resize', this.throttle)
        this.throttle()
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll)
        window.removeEventListener('resize', this.throttle)
    }
    
    render() {
        return (
            <Navbar 
                {...this.props }
                className={
                    (this.state.navbarSolid     ?  'solid '   :  '') +
                    (this.state.navbarHidden    &&
                    !this.state.navToggleActive ?  'hidden '  :  '') +
                    (!this.state.navbarSolid    &&
                    this.state.navToggleActive  ?  'solid'    :  this.onScroll)
                }
            >
                <Brand 
                    {...this.props}
                    ref={this.brandRef}
                >
                    <img src={Logo} alt="Logo" />
                </Brand>
            
                <LinksContainer
                    {...this.props}
                    ref={this.linksListRef}
                    className={
                        (this.state.mobileNav           ?  'hide '   :  '') +
                        (this.state.navToggleActive     ?  'open '   :  '')
                    }
                >
                    { this.createEachChild() }
                </LinksContainer>

                <Toggle 
                    {...this.props}
                    ref={this.toggle} 
                    onClick={() => {
                        this.toggleActive();
                        this.staggerAnimation(this.linksListRef.current)
                    }}
                    className={
                        (this.state.mobileNav                   ?  ''              :  'hide ' ) + 
                        (this.state.navToggleActive             ?  'active '       :   ''     ) +
                        (this.state.navToggleAnimationBlocked   ?  'blocked '      :   ''     )
                    }
                />
            </Navbar>
        )
    }
}